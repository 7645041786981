import ServiceManufactory from "../assets/service_manufactory.json";
import ServiceDistributor from "../assets/service_distributor.json";
import ServiceCustomer from "../assets/service_customer.json";
import ServiceLoyalty from "../assets/service_loyalty.json";
import ServiceElearning from "../assets/service_elearning.json";
import ServiceWarehouse from "../assets/service_customize.json";

const home = {
  banner: {
    title: {
      vi: `Công Nghệ Đột Phá`,
      en: `Disruptive Solutions`,
    },
    description: [
      {
        vi: `
          <div>
          Estuary là nhà tư vấn & triển khai dịch vụ <span class="highlight">chuyển đổi số</span>, cung cấp các giải pháp đổi mới sáng tạo & đột phá nhằm thúc đẩy và tối ưu hóa hoạt động thương mại - tiếp thị của các tập đoàn đa quốc gia và doanh nghiệp Việt Nam triển vọng.
          </div>
        `,
        en: `
          Estuary is a <span class="highlight">digital transformation</span> consultant and implementor dedicated to deliver disruptive growth-hacking technology solutions focusing on sales & marketing to large multinational companies & aspiring Vietnamese enterprises
        `,
      },
      {
        vi: `
          Chúng tôi tin vào <span class="highlight">Sức Mạnh Của Sự Minh Bạch.</span> Trong mọi vấn đề, 
          chúng tôi tìm hiểu tường tận gốc rễ và sử dụng công nghệ 
          như một công cụ để giải quyết các tình huống phức tạp.                
        `,
        en: `
          We believe the <span class="highlight">Power of Clarity.</span> 
          In everything we do, we look for the insight 
          and use the technology as a tool to answer complex situations.
        `,
      },
      {
        vi: `
                    
                `,
        en: `
                    
                `,
      },
    ],
  },
  our_work: {
    paragraph1: {
      vi: `<p>ESTUARY <span>đã và đang phát triển nhiều dự án tại Việt Nam và trên toàn thế giới.</span></p>`,
      en: `<p>ESTUARY <span>has been developing many projects in Vietnam and worldwide.</span></p>`,
    },
    paragraph2: {
      vi: `Chúng tôi tin rằng <span>Chuyển Đổi Số</span> là tương lai, và cũng chính là cuộc hành trình của Estuary.`,
      en: `We believe <span>Digital Transformation</span> is the future. It is the journey for the entire company.`,
    },
    paragraph3: {
      vi: `Điều đó không chỉ gói gọn trong <span>Đổi Mới Marketing,</span> mà còn bao gồm <span>Hiện Đại Hoá Doanh Nghiệp.</span>`,
      en: `It is no longer just about the <span>Marketing Transformation,</span> but the <span>Business Modernization.</span>`,
    },
    // paragraph4: {
    //     vi: `Mà còn bao gồm <span>Hiện Đại Hoá Doanh Nghiệp</span>`,
    //     en: `It is about the <span>Business Modernization</span>`
    // },
    paragraph5: {
      vi: `
                Chúng tôi cũng tin rằng việc áp dụng nền tảng công nghệ, kết hợp với kỹ năng hoạch định chiến lược marketing 
                sẽ là cơ sở vững chắc cho các đối tác của Estuary phát triển doanh nghiệp theo hướng sáng tạo, hiệu quả nhất.
            `,
      en: `
                We also believe that the application of technology platform combined with competency in planning marketing strategy 
                will be foundation for Estuary’s partners to grow their business in the most innovative, effective way.
            `,
    },
  },
  service: {
    title: {
      vi: `Giải pháp`,
      en: `Our Solutions`,
    },
    thinking: {
      vi: `<p>Các giải pháp <br/>Hiện Đại Hóa Doanh Nghiệp</p>`,
      en: `<p>Solutions for <br/>Business Transformation</p>`,
    },
    list: [
      {
        id: 3,
        slug: "internal-working-system",
        title: {
          vi: `Hệ thống Quản lý bán hàng <br/> trên ứng dụng di động (SaleX)`,
          en: `Sale Automation & CRM (SaleX)`,
        },
        title_text: {
          vi: `Hệ thống Quản lý bán hàng trên <br/> ứng dụng di động (SaleX)`,
          en: `Sale Automation & CRM (SaleX)`,
        },
        sub_title: {
          vi: `
            Là giải pháp phần mềm giúp tối đa hóa hiệu suất bán hàng cho đội ngũ kinh doanh bằng các công cụ kỹ thuật số (chăm sóc khách hàng trực tuyến, tự động gợi ý lộ trình bán hàng…) để khám phá, phục vụ và quản lý khách hàng vượt trội so với các phương pháp truyền thống.
            <br/><br/> Thông qua SaleX, nhà quản lý nhanh chóng nhận dữ liệu theo thời gian thực và sử dụng các công cụ tự động (cảnh báo hiệu suất làm việc…) để quản lý và đào tạo đội ngũ kinh doanh một cách hiệu quả.
          `,
          en: `
            A software solution that helps maximize sales performance by empowering sales teams with digital tools (e.g. e-detailing, automatic sales route allocation, etc.) to discover, recruit, service, and manage customers on top of the traditional approaches.
            <br/><br/> Through SaleX, sales managers and decision makers are empowered with real-time data and automated tools (e.g. performance alert, etc.) to effectively manage and direct their sales team
          `,
        },
        lotties: {
          component: ServiceCustomer,
          size: [500, 500],
        },
      },
      {
        id: 4,
        slug: "digital-loyalty-program",
        title: {
          vi: `Chăm sóc khách hàng <br/>thân thiết trên nền tảng <br/>kỹ thuật số (EngageX)`,
          en: `Customer Engagement Platform (EngageX)`,
        },
        title_text: {
          vi: `Chăm sóc khách hàng thân thiết trên nền tảng kỹ thuật số (EngageX)`,
          en: `Customer Engagement Platform (EngageX)`,
        },
        sub_title: {
          vi: `
          Là giải pháp phần mềm dành cho doanh nghiệp để tương tác với (i) người tiêu dùng, (ii) người nổi tiếng và (iii) nhà phân phối thông qua nền tảng số với phạm vi và tốc độ thực thi vượt trội so với cách thức truyền thống (chăm sóc khách hàng trực tiếp, thẻ khuyến mãi...).
          <br/><br/> Không như các phương pháp truyền thống có sự hạn chế trong việc nhận dữ liệu phản hồi, giải pháp EngageX có thể tổng hợp, hệ thống hóa một lượng lớn dữ liệu từ các bên liên quan để khai thác toàn bộ tiềm năng của doanh nghiệp.
          `,
          en: `
            A software solution for companies to digitally engage their (i) consumers, (ii) influencers, and (iii) distributors with execution scope and speed vastly superior to those of traditional methods (e.g. off-line detailing, physical vouchers, etc.).
            <br/><br/> Unlike the traditional approaches that offer limited data feedbacks, clients with EngageX may aggregate large amount of relevant data from stakeholders to unlock the full potentials of their businesses.
          `,
        },
        lotties: {
          component: ServiceLoyalty,
          size: [500, 500],
        },
      },
      {
        id: 2,
        slug: "distributor-management-system",
        title: {
          vi: `Hệ thống <br/> Quản lý nhà phân phối`,
          en: `Distributor <br/> Management System (DMS)`,
        },
        title_text: {
          vi: `Hệ thống Quản lý nhà phân phối`,
          en: `Distributor Management System (DMS)`,
        },
        sub_title: {
          vi: `
                        Cải thiện giao tiếp với đại lý, quản lý đơn hàng, dịch vụ sau bán hàng. 
                        Các đại lý có thể nhận yêu cầu hoặc đơn đặt hàng từ người dùng cuối thông qua ứng dụng di động
                    `,
          en: `
                        Enhance communication quality with dealers, order management & after-sales service. 
                        Dealers can receive inquiry or order from end-users via the mobile app, a model of O<small>2</small>O
                    `,
        },
        lotties: {
          component: ServiceDistributor,
          size: [500, 500],
        },
      },
      {
        id: 1,
        slug: "smart-manufacturing-platform-nexus",
        title: {
          vi: `Hệ thống <br/> Quản lý sản xuất (Nexus)`,
          en: `Smart Manufacturing <br/> Platform (Nexus)`,
        },
        title_text: {
          vi: `Hệ thống Quản lý sản xuất (Nexus)`,
          en: `Smart Manufacturing Platform (Nexus)`,
        },
        sub_title: {
          vi: `Quản lý dây chuyền sản xuất & truy xuất nguồn gốc chuỗi cung ứng trên nền tảng kỹ thuật số`,
          en: `Production line management & supply chain traceability on digital platform`,
        },
        lotties: {
          component: ServiceManufactory,
          size: [500, 500],
        },
      },
      {
        id: 6,
        slug: "smart-warehouse-management-system",
        title: {
          vi: `Hệ thống <br/> Quản lý kho thông minh`,
          en: `Smart Warehouse <br/> Management System (SWMS)`,
        },
        title_text: {
          vi: `Hệ thống Quản lý kho thông minh`,
          en: `Smart Warehouse Management System (SWMS)`,
        },
        sub_title: {
          vi: `Hiện đại hóa nhà kho thành trung tâm kho bãi thông minh, hiệu suất cao`,
          en: `Transform warehouse into a high performance & high efficiency centre`,
        },
        lotties: {
          component: ServiceWarehouse,
          size: [500, 230],
        },
      },

      {
        id: 5,
        slug: "e-learning-platform",
        title: {
          vi: `Hệ thống Giáo dục <br/>và Cấp bằng trực tuyến`,
          en: `E-Learning Platform`,
        },
        title_text: {
          vi: `Hệ thống Giáo dục và Cấp bằng trực tuyến`,
          en: `E-Learning Platform`,
        },
        sub_title: {
          vi: `Cung cấp nền tảng giúp người dạy và học kết nối với nhau trực tuyến và nhanh chóng`,
          en: `Online learning & e-Certificate on various digital platforms`,
        },
        lotties: {
          component: ServiceElearning,
          size: [500, 500],
        },
      },
    ],
  },
  news: {
    title: {
      vi: `Tin tức`,
      en: `News`,
    },
  },
  contact: {
    vi: `Kết nối với chúng tôi ngay hôm nay!`,
    en: `Connect With Us Today! `,
  },
  sticky_contact: {
    title: {
      vi: "Liên hệ ngay!",
      en: "Contact Us Now!",
    },
    left_panel: {
      title: {
        vi: "Gọi ngay <br/> cho chúng tôi",
        en: "Call us at <br/> Viet Nam",
      },
      phone: {
        vi: process.env.NEXT_PUBLIC_HOTLINE,
        en: process.env.NEXT_PUBLIC_HOTLINE,
      },
    },
    right_panel: {
      title: {
        vi: "Để lại thông tin liên hệ",
        en: "Leave your contact",
      },
      here: {
        vi: "tại đây",
        en: "here",
      },
      email: {
        vi: "info@estuary.solutions",
        en: "info@estuary.solutions",
      },
    },
  },
  footer: {
    left: {
      title: {
        vi: "LIÊN HỆ",
        en: "CONTACT",
      },
      email: {
        vi: "Email: info@estuary.solutions",
        en: "Email: info@estuary.solutions",
      },
      hotline: {
        vi: `Hotline: ${process.env.NEXT_PUBLIC_HOTLINE}`,
        en: `Hotline: ${process.env.NEXT_PUBLIC_HOTLINE}`,
      },
      address: {
        vi: "Trụ sở: Tòa nhà GB, Tầng 10 và 11, <br/>78 - 80 Cách Mạng Tháng 8, <br/>Phường Võ Thị Sáu, Quận 3, TP. HCM",
        en: "Headquarter: GB Tower, Level 10 and 11, <br/>78 - 80 Cach Mang Thang 8 St., <br/>Vo Thi Sau Ward, District 3, HCMC.",
      },
    },
    center: {
      title: {
        vi: "CÁC GIẢI PHÁP",
        en: "OUR SOLUTIONS",
      },
      // service1: {
      //     vi: "Hệ thống Quản lý sản xuất",
      //     en: "Manufacturing Execution System (MES)"
      // },
      // service2: {
      //     vi: "Hệ thống Quản lý nhà phân phối",
      //     en: "Distributor Management System (DMS)"
      // },
      // service3: {
      //     vi: "Hệ thống Quản lý bán hàng trên ứng dụng di động (IWS)",
      //     en: "Internal Working System (IWS)"
      // },
      // service4: {
      //     vi: "Chăm sóc khách hàng thân thiết trên nền tảng kỹ thuật số",
      //     en: "Customer Engagement Platform"
      // },
      // service5: {
      //     vi: "Hệ thống Giáo dục và Cấp bằng trực tuyến",
      //     en: "E - Learning"
      // },
      // service6: {
      //     vi: "Hệ thống tuỳ chỉnh theo yêu cầu",
      //     en: "Customized Platform"
      // },
    },
    right: {
      title: {
        vi: "VỀ ESTUARY SOLUTIONS",
        en: "ABOUT ESTUARY",
      },
      // home: {
      //     vi: "Trang chủ",
      //     en: "Homepage"
      // },
      introduction: {
        vi: "Về chúng tôi",
        en: "About us",
      },
      news: {
        vi: "Tin tức",
        en: "News",
      },
    },
  },
};

export default home;
